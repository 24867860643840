import { Component } from "client";
import { TextInputComponentDisplay } from "./TextInputComponent";
import { TextOutputComponentDisplay } from "./TextOutputComponent";
import { ImageInputComponentDisplay } from "./ImageInputComponent";
import { AudioInputComponentDisplay } from "./AudioInputComponent";
import { AudioOutputComponentDisplay } from "./AudioOutputComponent";
import { ImageOutputComponentDisplay } from "./ImageOutputComponent";

export type ComponentDisplayProps = {
  component: Component;
};

export const ComponentDisplay = ({ component }: ComponentDisplayProps) => {
  if (component.configuration.component_type === "TextInput") {
    return <TextInputComponentDisplay component={component} />;
  }

  if (component.configuration.component_type === "ImageInput") {
    return <ImageInputComponentDisplay component={component} />;
  }

  if (component.configuration.component_type === "ImageOutput") {
    return <ImageOutputComponentDisplay component={component} />;
  }

  if (component.configuration.component_type === "AudioInput") {
    return <AudioInputComponentDisplay component={component} />;
  }

  if (component.configuration.component_type === "TextOutput") {
    return <TextOutputComponentDisplay component={component} />;
  }

  if (component.configuration.component_type === "AudioOutput") {
    return <AudioOutputComponentDisplay component={component} />;
  }

  return <></>;
};
