import * as React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { ProjectComponentResult } from "client";
import useProjectStore from "storage/ProjectStore";
import { shortId } from "lib/id";
import ReactJson from "react-json-view";
import { formatSecs } from "lib/time";
import Status from "components/status";

export type ComponentResultItemProps = {
  componentResult: ProjectComponentResult;
};

const ComponentResultItemCard = ({
  componentResult,
}: ComponentResultItemProps) => {
  const component = useProjectStore((state) =>
    state.components.find(
      (component) => component.id === componentResult.component_id,
    ),
  );

  if (!component) return <></>;

  return (
    <Card className="w-full">
      <CardHeader>
        <CardDescription>
          {component.configuration.component_type} (#{shortId(component.id)})
        </CardDescription>
        <CardTitle>{component.component_name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="w-full items-center gap-4">
          <div className="flex justify-between">
            <div>
              <p className="text-sm text-muted-foreground">Status</p>
              <Status isSuccess={componentResult.is_success} />
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Run At</p>
              <p>{formatSecs(componentResult.start_at)}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Duration</p>
              <p>{componentResult.end_at - componentResult.start_at}s</p>
            </div>
          </div>
          <div className="flex flex-col space-y-1.5 pt-2">
            <Label>Input</Label>
            <ReactJson src={componentResult.input as object} />
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label>Output</Label>
            <ReactJson
              src={componentResult.output as object}
              collapsed={true}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ComponentResultItemCard;
