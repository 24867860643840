import { Drawer, DrawerContent, DrawerTrigger } from "components/ui/drawer";

export type ViewProjectAppDialogProps = {
  projectId: string;
  children: React.ReactNode;
};

const ViewProjectAppDrawer = ({
  children,
  projectId,
}: ViewProjectAppDialogProps) => {
  const url = `${window.origin}/projects/${projectId}/app`;
  return (
    <Drawer>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerContent className="h-[90vh]">
        <iframe src={url} className="w-full h-full" />
      </DrawerContent>
    </Drawer>
  );
};

export default ViewProjectAppDrawer;
