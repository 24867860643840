import React from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  type EdgeProps,
} from "@xyflow/react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { XIcon } from "lucide-react";
import useProjectStore from "storage/ProjectStore";
import { putProjectsByProjectId } from "client";

export default function ComponentEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = async () => {
    const [sourceId, targetId] = id.split("_");
    const activeProject = useProjectStore.getState().activeProject;

    if (activeProject && sourceId && targetId) {
      const item = activeProject.graph.items.find(
        (item) => item.component_id === sourceId,
      );
      if (item) {
        const targetIndex = item.next_component_ids.indexOf(targetId);
        item.next_component_ids.splice(targetIndex, 1);
      }
      const data = await putProjectsByProjectId({
        path: {
          project_id: activeProject.id,
        },
        body: {
          name: activeProject.name,
          description: activeProject.description,
          graph: activeProject.graph,
        },
      });
      if (data.data) {
        useProjectStore.getState().setActiveProject({ ...activeProject });
      }
    }
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <circle r="5" fill="#5C625B">
        <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} />
      </circle>
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                size="icon"
                variant="ghost"
                className="rounded-full w-6 h-6"
              >
                <XIcon />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  Do you want to remove the path?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={onEdgeClick}>
                  Continue Remove
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
