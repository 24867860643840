import { Blocks } from "lucide-react";
import { UserAuthForm } from "./AuthForm";
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function AuthenticationPage() {
  return (
    <>
      <div className="container relative hidden h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <Blocks className="pr-2" />
            Ez AI Space
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;This app lets us build and test AI ideas within minutes
                and seamlessly integrate them into our application. It’s a
                powerful tool that speeds up development and enhances our AI
                capabilities!&rdquo;
              </p>
              <footer className="text-sm">Rodgers Nguyen - SWE at Axon</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Welcome back!
              </h1>
            </div>
            <GoogleOAuthProvider clientId="441850897535-l0k9mkf5l6p9iv98tb8n0ov1pmfkl19i.apps.googleusercontent.com">
              <UserAuthForm />
            </GoogleOAuthProvider>
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
