import * as React from "react";

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "components/ui/drawer";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { ComponentLogo } from "components/ComponentLogo";
import { putComponentsByComponentId, TextToImageAttributes } from "client";
import { Handle, Position } from "@xyflow/react";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import cloneDeep from "lodash/cloneDeep";
import useProjectStore from "storage/ProjectStore";
import { NodeDataType } from "./sharedType";
import NodeFooter from "./NodeFooter";
import CreateNodeConnect, { ConnectType } from "components/CreateNodeConnect";
import { shortId } from "lib/id";
import { Textarea } from "components/ui/textarea";

const TextToImageNode = ({ data }: { data: NodeDataType }) => {
  const [open, setOpen] = React.useState(false);
  const [innerComponent, setInnerComponent] = React.useState(cloneDeep(data));
  const setComponent = useProjectStore((state) => state.setComponent);

  const onChangeName = (name: string) => {
    innerComponent.component_name = name;
    setInnerComponent({ ...innerComponent });
  };

  const onChangeInstruction = (instruction: string) => {
    (
      innerComponent.configuration.attributes as TextToImageAttributes
    ).instruction = instruction;
    setInnerComponent({ ...innerComponent });
  };

  const onSave = async () => {
    const data = await putComponentsByComponentId({
      path: {
        component_id: innerComponent.id,
      },
      body: {
        component_name: innerComponent.component_name,
        configuration: innerComponent.configuration,
      },
    });

    if (data.data) {
      setComponent(data.data);
      setOpen(false);
    }
  };

  const attributes = innerComponent.configuration
    .attributes as TextToImageAttributes;
  const instruction = attributes.instruction;

  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={true}
        draggable={false}
      />
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={true}
        draggable={false}
      />
      {!data.readOnly && (
        <div className="flex justify-center">
          <CreateNodeConnect
            componentId={data.id}
            connectType={ConnectType.INPUT}
          />
        </div>
      )}
      <Drawer direction="bottom" open={open} onOpenChange={setOpen}>
        <DrawerTrigger>
          <InnerTextToImageNode data={data} />
        </DrawerTrigger>
        <DrawerContent>
          <div className="flex">
            <div className="flex-1">
              <DrawerHeader>
                <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                  Text to Image Configuration
                </h3>
                <DrawerDescription>
                  Text to Image (#{shortId(data.id)})
                </DrawerDescription>
                <DrawerTitle>
                  <Label htmlFor={data.id}>Name</Label>
                  <Input
                    value={innerComponent.component_name}
                    id={data.id}
                    onChange={(e) => onChangeName(e.currentTarget.value)}
                    readOnly={data.readOnly}
                  />
                </DrawerTitle>
              </DrawerHeader>
              <div className="gap-1.5 p-4">
                <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                  Attributes
                </h3>
              </div>
            </div>
            <div className="flex-1 p-2">
              <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight pb-2">
                Prompt to guide AI (LLM)
              </h3>
              <p className="text-sm text-muted-foreground">
                You can improve quality of output by chat and explain your idea
                with AI (LLM) -{" "}
                <a
                  href="https://www.promptingguide.ai/techniques/fewshot"
                  target="_blank"
                  className="font-bold"
                >
                  Few shot example
                </a>
              </p>
              <p className="font-bold">Instruction</p>
              <Textarea
                value={instruction}
                rows={6}
                onChange={(e) => onChangeInstruction(e.currentTarget.value)}
                readOnly={data.readOnly}
              />
            </div>
          </div>
          <NodeFooter
            componentId={data.id}
            readOnly={data.readOnly}
            onSave={onSave}
          />
        </DrawerContent>
      </Drawer>
      {!data.readOnly && (
        <div className="flex justify-center">
          <CreateNodeConnect
            componentId={data.id}
            connectType={ConnectType.OUTPUT}
          />
        </div>
      )}
    </>
  );
};

export const InnerTextToImageNode = ({ data }: { data: NodeDataType }) => {
  return (
    <Card className="w-[300px] cursor-pointer">
      <div className="flex items-center">
        <div className="space-y-1.5 pl-6">
          <ComponentLogo src={"/brands/openai-logomark.png"} text="OpenAI" />
        </div>
        <CardHeader className="p-3">
          <CardDescription>Text to Image (#{shortId(data.id)})</CardDescription>
          <CardTitle className="text-sm text-left">
            {data.component_name}
          </CardTitle>
        </CardHeader>
      </div>
    </Card>
  );
};

export default TextToImageNode;
