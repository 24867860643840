import { cn } from "lib/utils";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Project } from "client";

type ProjectItemCardProps = { project: Project } & React.ComponentProps<
  typeof Card
>;

export function ProjectItemCard({
  project,
  onClick,
  className,
  ...props
}: ProjectItemCardProps) {
  return (
    <Card
      className={cn("w-[380px] h-[280px] flex flex-col", className)}
      {...props}
    >
      <CardHeader className="flex-1">
        <a href={`/projects/${project.id}`}>
          <CardTitle>{project.name}</CardTitle>
        </a>
        <CardDescription className="truncate">
          {project.description}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div>
          <div className="space-y-1 w-full">
            <p className="text-sm font-medium leading-none">Components</p>
            <p className="text-sm text-muted-foreground">
              {project.graph.items.length}
            </p>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button variant="outline" className="w-full" asChild>
          <a href={`/projects/${project.id}`}>View project</a>
        </Button>
      </CardFooter>
    </Card>
  );
}
