import {
  Component,
  EzEngineValue,
  getProjectsByProjectIdComponents,
  getProjectsByProjectId,
  Project,
  ProjectResultResponse,
  getBasedComponents,
  ComponentTypeConfig,
  getComponentTypeConfigs,
  getMainProjectsByMainProjectIdResults,
  MainProject,
  getMainProjectsByMainProjectId,
} from "client";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { create } from "zustand";

type ProjectState = {
  activeProject?: Project;
  setActiveProject: (project?: Project) => void;
  components: Component[];
  setComponents: (components: Component[]) => void;
  setComponent: (component: Component) => void;
  removeComponent: (componentId: string) => void;
  inputs: { [componentName: string]: EzEngineValue };
  setInputs: (input: { [componentName: string]: EzEngineValue }) => void;
  setInput: (componentName: string, value: EzEngineValue) => void;
  results: ProjectResultResponse[];
  setResults: (results: ProjectResultResponse[]) => void;
  addResult: (result: ProjectResultResponse) => void;
  basedComponents: Component[];
  setBasedComponents: (basedComponents: Component[]) => void;
  componentTypeConfigs: ComponentTypeConfig;
  setComponentTypeConfigs: (config: ComponentTypeConfig) => void;
  mainProject?: MainProject;
  setMainProject: (mainProject: MainProject) => void;
  reset: () => void;
};

const useProjectStore = create<ProjectState>((set, get) => ({
  activeProject: undefined,
  setActiveProject: (project) => set({ activeProject: project }),
  components: [],
  setComponents: (components) => set({ components }),
  setComponent: (component) => {
    const currentComponents = get().components;
    const index = currentComponents.findIndex((c) => c.id === component.id);
    if (index > -1) {
      currentComponents[index] = component;
    } else {
      currentComponents.push(component);
    }
    set({ components: [...currentComponents] });
  },
  removeComponent: (componentId) => {
    const currentComponents = get().components;
    const index = currentComponents.findIndex((c) => c.id === componentId);
    if (index > -1) currentComponents.splice(index, 1);
    set({ components: [...currentComponents] });
  },
  inputs: {},
  setInputs: (inputs) => set({ inputs }),
  setInput: (componentName, value) => {
    const currentInputs = get().inputs;
    currentInputs[componentName] = value;
    set({ inputs: currentInputs });
  },
  results: [],
  setResults: (results) => set({ results }),
  addResult: (result) => {
    const currentResults = get().results;
    if (!currentResults.some((r) => r.result.id === result.result.id)) {
      currentResults.push(result);
    }
    set({ results: [...currentResults] });
  },
  basedComponents: [],
  setBasedComponents: (basedComponents) => {
    set({ basedComponents });
  },
  componentTypeConfigs: {
    component_type_input: [],
    component_type_output: [],
    component_type_processing: [],
  },
  setComponentTypeConfigs: (config) => set({ componentTypeConfigs: config }),
  mainProject: undefined,
  setMainProject: (mainProject: MainProject) => set({ mainProject }),
  reset: () => {
    set({
      activeProject: undefined,
      components: [],
      results: [],
      mainProject: undefined,
    });
  },
}));

export default useProjectStore;

export const useProjectResults = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isPreview = searchParams.get("preview") === "true";
  const results = useProjectStore((state) => state.results);
  const mainProject = useProjectStore((state) => state.mainProject);
  const currentProject = useProjectStore((state) => state.activeProject);

  if (isPreview) {
    return results.filter(
      (result) => result.result.project_id === currentProject?.id,
    );
  }

  if (currentProject?.id === mainProject?.active_project_id) {
    return results;
  }

  return results.filter(
    (result) => result.result.project_id === currentProject?.id,
  );
};

export const useLastResult = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resultId = searchParams.get("resultId");

  const results = useProjectResults();
  if (resultId) {
    return results.find((result) => result.result.id === resultId);
  }

  return results.sort((a, b) => b.result.run_count - a.result.run_count)[0];
};

export const useLoadProject = (projectId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const setActiveProject = useProjectStore((state) => state.setActiveProject);
  const setComponents = useProjectStore((state) => state.setComponents);
  const setResults = useProjectStore((state) => state.setResults);
  const setBasedComponents = useProjectStore(
    (state) => state.setBasedComponents,
  );
  const setComponentTypeConfigs = useProjectStore(
    (state) => state.setComponentTypeConfigs,
  );
  const setMainProject = useProjectStore((state) => state.setMainProject);
  const reset = useProjectStore((state) => state.reset);

  useEffect(() => {
    reset();
    load();
  }, [projectId]);

  const load = async () => {
    try {
      setLoading(true);
      const res = await getProjectsByProjectId({
        path: { project_id: projectId },
      });
      if (res.data) {
        setActiveProject(res.data);
        if (res.data.main_project_id) {
          const mainProjectRes = await getMainProjectsByMainProjectId({
            path: {
              main_project_id: res.data.main_project_id,
            },
          });

          if (mainProjectRes.data) {
            setMainProject(mainProjectRes.data);
          }
        }
        const componentRes = await getProjectsByProjectIdComponents({
          path: {
            project_id: projectId,
          },
        });
        setComponents(componentRes?.data || []);
        const projectResultsRes = await getMainProjectsByMainProjectIdResults({
          path: {
            main_project_id: res.data.main_project_id || "",
          },
        });
        setResults(projectResultsRes?.data || []);
        const basedRes = await getBasedComponents();
        if (basedRes.data) setBasedComponents(basedRes.data);
        const componentTypeConfigData = await getComponentTypeConfigs();
        if (componentTypeConfigData.data) {
          setComponentTypeConfigs(componentTypeConfigData.data);
        }
      }
    } catch (e) {
      console.error("Cannot load project data");
    } finally {
      setLoading(false);
    }
  };

  return { loading };
};
