import * as React from "react";

import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { ComponentLogo } from "components/ComponentLogo";
import { Component } from "client";

const NodeSupportedNode = ({ data }: { data: Component }) => {
  return (
    <Card className="w-[300px]">
      <div className="flex items-center">
        <div className="space-y-1.5 pl-6">
          <ComponentLogo text="I" />
        </div>
        <CardHeader className="p-3">
          <CardDescription>Unknown</CardDescription>
          <CardTitle>Unknown Node: {data.id}</CardTitle>
        </CardHeader>
      </div>
    </Card>
  );
};

export default NodeSupportedNode;
