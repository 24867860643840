import { create } from "zustand";
import { isCollapse, setCollapse } from "./CollapseStorage";

type UIState = {
  menuCollapse: boolean;
  setMenuCollapsible: (menuCollapse: boolean) => void;
};

const useUIStore = create<UIState>((set, get) => ({
  menuCollapse: isCollapse(),
  setMenuCollapsible: (menuCollapse) => {
    setCollapse(menuCollapse);
    set({
      menuCollapse,
    });
  },
}));

export default useUIStore;
