import { ReactFlow, Background, Controls, Edge } from "@xyflow/react";

import TextInputNode from "./nodes/TextInputNode";
import TextOutputNode from "./nodes/TextOutputNode";
import AIChatNode from "./nodes/AIChatNode";
import UnknownNode from "./nodes/UnknownNode";
import { Component, GraphItem, Project } from "client";
import useProjectStore from "storage/ProjectStore";
import { ProjectNode } from "./nodes/sharedType";
import ComponentEdge from "./ComponentEdge";
import CreateNode from "./nodes/CreateStartNode";
import ImageInputNode from "./nodes/ImageInputNode";
import AudioInputNode from "./nodes/AudioInputNode";
import SpeechToTextNode from "./nodes/SpeechToTextNode";
import TextToSpeechNode from "./nodes/TextToSpeechNode";
import AudioOutputNode from "./nodes/AudioOutputNode";
import TextToImageNode from "./nodes/TextToImageNode";
import ImageOutputNode from "./nodes/ImageOutputNode";

import "@xyflow/react/dist/style.css";

const nodeTypes = {
  TextInput: TextInputNode,
  ImageInput: ImageInputNode,
  AudioInput: AudioInputNode,
  AudioOutput: AudioOutputNode,
  TextOutput: TextOutputNode,
  AIChat: AIChatNode,
  SpeechToText: SpeechToTextNode,
  TextToSpeech: TextToSpeechNode,
  TextToImage: TextToImageNode,
  ImageOutput: ImageOutputNode,
  CreateNode: CreateNode,
  Unknown: UnknownNode,
};

export const getPosition = (row: number, column: number): [number, number] => {
  const x = column * 300 + (column + 1) * 10;

  if (row <= 1) {
    return [row * 200 + 10, x];
  }

  return [row * 200 + 10 + 36 * (row - 1), x];
};

export const convertComponentToNode = (
  component: Component,
  row: number,
  column: number,
  readOnly?: boolean,
): ProjectNode => {
  const [y, x] = getPosition(row, column);
  // const y = row * 200 + 10;
  // const x = column * 300 + (column + 1) * 10;
  return {
    id: component.id,
    data: { ...component, readOnly },
    position: { x, y },
    type: component.configuration.component_type,
    draggable: false,
  };
};

export const markRows = (
  project: Project,
): [{ [componentId: string]: number }, number] => {
  const res = {};
  let currentRow = 0;

  const flatNextIds: string[] = [];
  project.graph.items.forEach((item) => {
    flatNextIds.push(...item.next_component_ids);
  });
  project.graph.items
    .filter((item) => !flatNextIds.includes(item.component_id))
    .forEach((item) => {
      res[item.component_id] = currentRow;
    });

  while (currentRow <= project.graph.items.length) {
    const headItems = Object.entries(res)
      .filter(([_, row]) => row === currentRow)
      .map(([componentId]) => componentId)
      .map((componentId) =>
        project.graph.items.find((item) => item.component_id === componentId),
      )
      .filter((item) => !!item) as GraphItem[];

    currentRow += 1;
    headItems.forEach((headItem) => {
      headItem.next_component_ids.forEach((nextComponentId) => {
        res[nextComponentId] = currentRow;
      });
    });
  }

  return [res, (currentRow += 1)];
};

export const layoutProject = (
  project: Project,
  components: Component[],
  readOnly?: boolean,
): [ProjectNode[], Edge[]] => {
  const nodeLayers: Component[][] = [];
  const [itemRows, maxRow] = markRows(project);

  for (let i = 0; i < maxRow; i++) {
    nodeLayers.push([]);
  }
  components.forEach((component) => {
    const componentRow = itemRows[component.id];
    if (componentRow !== undefined) {
      nodeLayers[componentRow]?.push(component);
    }
  });

  const nodes: ProjectNode[] = [];
  nodeLayers.forEach((nodeLayer, row) => {
    nodeLayer.forEach((component, column) => {
      nodes.push(convertComponentToNode(component, row, column, readOnly));
    });
  });

  if (!readOnly) {
    const column = nodeLayers[0]?.length || 0;
    const y = 10;
    const x = column * 300 + (column + 1) * 10;
    const data: ProjectNode = {
      id: "CreateNode",
      data: {},
      position: { x, y },
      type: "CreateNode",
      draggable: false,
    };

    nodes.push(data);
  }

  const edges: Edge[] = [];
  project.graph.items.forEach((item) => {
    item.next_component_ids.forEach((nextComponentId) => {
      edges.push({
        id: `${item.component_id}_${nextComponentId}`,
        source: item.component_id,
        target: nextComponentId,
        type: !readOnly ? "ComponentEdge" : undefined,
      });
    });
  });

  return [nodes, edges];
};

const edgeTypes = {
  ComponentEdge: ComponentEdge,
};

export type ProjectFlowProps = {
  project: Project;
  readOnly?: boolean;
};

const ProjectFlow = ({ project, readOnly }: ProjectFlowProps) => {
  const components = useProjectStore((state) => state.components);
  const [nodes, edges] = layoutProject(project, components, readOnly);

  return (
    <div style={{ height: "100%" }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        draggable={true}
        minZoom={0.5}
        maxZoom={1.2}
        fitView
      >
        <Controls
          position="top-right"
          showFitView={false}
          showInteractive={false}
        />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default ProjectFlow;
