import * as React from "react";
import Markdown from "react-markdown";

import { Component, EzEngineValue, TextOutputAttributes } from "client";
import { useLastResult } from "storage/ProjectStore";
import { Separator } from "components/ui/separator";

export const formatEzValue = (value: EzEngineValue) => {
  let result = "";

  if (value.type === "String" && value.value) {
    console.log("Hello", value.value);
    result += value.value;
    result += "\n";
  } else if (value.type === "Multiples") {
    Object.entries(value.inputs).forEach(([componentName, value]) => {
      result += `# ${componentName} \n`;
      result += formatEzValue(value);
      result += "\n";
    });
  } else {
    result = "No support result. Click download to view result in JSON file";
  }

  return result;
};

export type TextOutputComponentDisplayProps = {
  component: Component;
  preview?: boolean;
};

export const TextOutputComponentDisplay: React.FC<
  TextOutputComponentDisplayProps
> = ({ component, preview }) => {
  const lastResult = useLastResult();
  const output = lastResult?.components?.find(
    (c) => c.component_id === component.id,
  )?.output;

  let result = "";
  if (output) {
    result = formatEzValue(output.result);
  }
  const label =
    (component.configuration.attributes as TextOutputAttributes).label ||
    component.component_name;

  return (
    <div className="grid w-full gap-1.5">
      <Separator className="my-4" />
      <div className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        {label}
      </div>
      <div className="prose" id={component.id}>
        {preview && <Markdown>{"**Result Here**"}</Markdown>}
        {!preview && <Markdown>{result || "*No result*"}</Markdown>}
      </div>
    </div>
  );
};
