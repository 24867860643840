const localConfig = {
  apiServerUrl: "http://localhost:8000/api",
  clarityProjectId: "ozg8n7lt72",
};

const prodConfig = {
  apiServerUrl: "https://api.ez-ai.space/api",
  clarityProjectId: "ozg8n7lt72",
};

const getConfig = () => {
  if (process.env.NODE_ENV === "production") {
    return prodConfig;
  }

  return localConfig;
};

export default getConfig();
