import { CloudIcon, FileIcon, Loader2 } from "lucide-react";
import React, { useState, DragEvent, ChangeEvent, useEffect } from "react";
import { File as FileRes, postFilesByFileIdDownload } from "client";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { uploadFile } from "lib/upload";

type FileUploaderProps = {
  onCompleteUpload: (file: FileRes) => void;
  accept?: string;
  readOnly?: boolean;
  fileDefault?: FileRes;
};

const FileUploader: React.FC<FileUploaderProps> = ({
  onCompleteUpload,
  accept,
  readOnly,
  fileDefault,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState<Error | null>(null);
  const [fileRes, setFileRes] = useState<FileRes | undefined>(fileDefault);
  const [fileUploaded, setFileUploaded] = useState<File | null>(null);
  const [selectedFileName, setSelectedFilename] = useState(fileDefault?.name);

  useEffect(() => {
    if (fileDefault && fileDefault.id !== fileRes?.id) {
      setFileRes(fileDefault);
    }

    if (fileDefault && fileDefault.name !== selectedFileName) {
      setSelectedFilename(fileDefault.name);
    }
  }, [fileDefault]);

  const onUploadFile = async (file: File) => {
    if (readOnly) return;
    if (file === fileUploaded) return;

    setUploading(true);
    setUploadFailed(null);
    const fileRes = await uploadFile(file);
    if (fileRes instanceof Error) {
      setUploadFailed(fileRes);
    } else {
      onCompleteUpload(fileRes as FileRes);
      setFileRes(fileRes as FileRes);
      setFileUploaded(file);
    }
    setUploading(false);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const selectedFile = e.dataTransfer.files[0];
      onUploadFile(selectedFile);
      setSelectedFilename(selectedFile.name);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      onUploadFile(selectedFile);
      setSelectedFilename(selectedFile.name);
    }
  };

  const onClickPreview = async () => {
    if (!fileRes) return;
    const downloadRes = await postFilesByFileIdDownload({
      path: {
        file_id: fileRes.id,
      },
    });

    if (downloadRes.data) {
      window.open(downloadRes.data, "_blank");
    }
  };

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center w-full p-6 border-2 border-dashed rounded-lg ${
          isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
        }`}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <CloudIcon size={48} />
        <p className="mt-2 text-gray-600">Drag & Drop your file here</p>
        <p className="text-sm text-gray-500">or</p>
        <Label
          htmlFor="upload-file"
          className="px-4 py-2 mt-2 text-white bg-blue-600 rounded cursor-pointer hover:bg-blue-700"
        >
          Browse File
        </Label>
        <Input
          id="upload-file"
          type="file"
          className="hidden"
          accept={accept}
          onChange={handleFileChange}
        />
      </div>
      <div className="w-full flex flex-col gap-2">
        {selectedFileName && (
          <div>
            <div
              className="flex gap-2 pt-2 pb-2 items-center cursor-pointer"
              onClick={onClickPreview}
            >
              {uploading && <Loader2 className="animate-spin" />}
              {!uploading && <FileIcon />}
              <small className="text-sm font-medium leading-none">
                {selectedFileName}
              </small>
              {uploadFailed && <p>{uploadFailed?.message}</p>}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploader;
