import React from "react";

function NotFound() {
  return (
    <div>
      <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        Not Found
      </h2>
    </div>
  );
}

export default NotFound;
