import * as React from "react";

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "components/ui/drawer";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { ComponentLogo } from "components/ComponentLogo";
import { putComponentsByComponentId, TextOutputAttributes } from "client";
import { Handle, Position } from "@xyflow/react";
import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import useProjectStore from "storage/ProjectStore";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { TextOutputComponentDisplay } from "components/ProjectDisplay/components/TextOutputComponent";
import { NodeDataType } from "./sharedType";
import NodeFooter from "./NodeFooter";
import CreateNodeConnect, { ConnectType } from "components/CreateNodeConnect";
import { shortId } from "lib/id";

const TextOutputNode = ({ data }: { data: NodeDataType }) => {
  const [open, setOpen] = useState(false);
  const [innerComponent, setInnerComponent] = useState(cloneDeep(data));
  const setComponent = useProjectStore((state) => state.setComponent);

  const onChangeName = (name: string) => {
    innerComponent.component_name = name;
    setInnerComponent({ ...innerComponent });
  };

  const onChangeLabel = (label: string) => {
    const attributes = innerComponent.configuration
      .attributes as TextOutputAttributes;
    attributes.label = label;
    innerComponent.configuration.attributes = attributes;
    setInnerComponent({ ...innerComponent });
  };

  const onSave = async () => {
    const data = await putComponentsByComponentId({
      path: {
        component_id: innerComponent.id,
      },
      body: {
        component_name: innerComponent.component_name,
        configuration: innerComponent.configuration,
      },
    });

    if (data.data) {
      setComponent(data.data);
      setOpen(false);
    }
  };

  const attributes = innerComponent.configuration
    .attributes as TextOutputAttributes;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={true}
        draggable={false}
      />
      {!data.readOnly && (
        <div className="flex justify-center">
          <CreateNodeConnect
            componentId={data.id}
            connectType={ConnectType.INPUT}
          />
        </div>
      )}
      <Drawer direction="bottom" open={open} onOpenChange={setOpen}>
        <DrawerTrigger>
          <InnerTextOutputNode data={data} />
        </DrawerTrigger>
        <DrawerContent>
          <div className="flex">
            <div className="flex-1">
              <DrawerHeader>
                <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                  Text Output Configuration
                </h3>
                <DrawerDescription>
                  Text Output (#{shortId(data.id)})
                </DrawerDescription>
                <DrawerTitle>
                  <Label htmlFor={data.id}>Name</Label>
                  <Input
                    value={innerComponent.component_name}
                    id={data.id}
                    onChange={(e) => onChangeName(e.currentTarget.value)}
                  />
                </DrawerTitle>
              </DrawerHeader>
              <div className="gap-1.5 p-4">
                <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                  Attributes
                </h3>
                <Label htmlFor={`Label ${data.id}`}>Output Label</Label>
                <Input
                  placeholder="Typing your label"
                  id={`Label ${data.id}`}
                  value={attributes.label}
                  onChange={(e) => onChangeLabel(e.currentTarget.value)}
                />
              </div>
              <NodeFooter
                componentId={data.id}
                readOnly={data.readOnly}
                onSave={onSave}
              />
            </div>
            <div className="flex-1 p-2">
              <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight pb-2">
                Preview Display
              </h3>
              <TextOutputComponentDisplay preview component={innerComponent} />
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const InnerTextOutputNode = ({ data }: { data: NodeDataType }) => {
  return (
    <Card className="w-[300px] cursor-pointer">
      <div className="flex items-center">
        <div className="space-y-1.5 pl-6">
          <ComponentLogo text="O" />
        </div>
        <CardHeader className="p-3">
          <CardDescription>Text Output (#{shortId(data.id)})</CardDescription>
          <CardTitle className="text-sm text-left">
            {data.component_name}
          </CardTitle>
        </CardHeader>
      </div>
    </Card>
  );
};

export default TextOutputNode;
