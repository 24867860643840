import * as React from "react";

import {
  AudioOutputAttributes,
  Component,
  EzEngineValue,
  postFilesByFileIdDownload,
} from "client";
import { useLastResult } from "storage/ProjectStore";
import { Separator } from "components/ui/separator";

export const getAudioFile = (value: EzEngineValue) => {
  if (value.type === "Audio") {
    return value.file_id;
  } else if (value.type === "Multiples") {
    for (const innerVal of Object.values(value.inputs)) {
      const audioFileId = getAudioFile(innerVal);
      if (!!audioFileId) return audioFileId;
    }
  } else {
    return;
  }
};

export type AudioOutputComponentDisplayProps = {
  component: Component;
  preview?: boolean;
};

export const AudioOutputComponentDisplay: React.FC<
  AudioOutputComponentDisplayProps
> = ({ component }) => {
  const [downloadUrl, setDownloadUrl] = React.useState<string | undefined>();
  const lastResult = useLastResult();
  const output = lastResult?.components?.find(
    (c) => c.component_id === component.id,
  )?.output;

  React.useEffect(() => {
    if (output) {
      const fileId = getAudioFile(output.result);
      if (fileId) {
        load(fileId);
      }
    }
  }, [output]);

  const load = async (fileId: string) => {
    const downloadRes = await postFilesByFileIdDownload({
      path: {
        file_id: fileId,
      },
    });

    if (downloadRes.data) setDownloadUrl(downloadRes.data);
  };

  const label =
    (component.configuration.attributes as AudioOutputAttributes).label ||
    component.component_name;

  return (
    <div className="grid w-full gap-1.5">
      <Separator className="my-4" />
      <div className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        {label}
      </div>
      <div className="w-full">
        <audio controls src={downloadUrl}></audio>
      </div>
    </div>
  );
};
