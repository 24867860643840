import React, { useEffect, useState } from "react";
import { Project, getProjects, postProjects } from "client";
import { ProjectItemCard } from "components/ProjectItemCard";
import styled from "styled-components";
import Layout from "components/Layout";
import { Button } from "components/ui/button";
import { useNavigate } from "react-router-dom";
import { Plus, Loader2 } from "lucide-react";
import SecurePageWrapper from "components/SecurePageWrapper";

const ProjectsPageWrapper = () => {
  return (
    <SecurePageWrapper>
      <Layout>
        <ProjectsPage />
      </Layout>
    </SecurePageWrapper>
  );
};

function ProjectsPage() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    getProjects().then((res) => setProjects(res?.data || []));
  }, []);

  const onCreateProject = async () => {
    setCreating(true);
    const data = await postProjects({
      body: {
        name: "New Project",
        description: "",
        graph: {
          items: [],
        },
      },
    });

    if (data.data) {
      navigate(`/projects/${data.data.id}/draft`);
    }
    setCreating(false);
  };

  return (
    <div>
      <div className="flex justify-between pb-5">
        <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
          Projects
        </h2>
        <Button size="sm" disabled={creating} onClick={onCreateProject}>
          {creating ? <Loader2 className="animate-spin" /> : <Plus />} Create a
          Project
        </Button>
      </div>
      <ListWrapper>
        {projects.map((project) => (
          <ProjectItemCard project={project} key={project.id} />
        ))}
      </ListWrapper>
    </div>
  );
}

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export default ProjectsPageWrapper;
