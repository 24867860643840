import { PlayIcon } from "@radix-ui/react-icons";
import { postProjectsByProjectIdRun } from "client";
import { ComponentDisplay } from "./components";
import { Button } from "components/ui/button";
import { fromNowSecs } from "lib/time";
import { useState } from "react";
import useProjectStore, { useLastResult } from "storage/ProjectStore";
import { getDuration } from "lib/project_result";
import { Loader2 } from "lucide-react";

const ProjectDisplay = () => {
  const activeProject = useProjectStore((state) => state.activeProject);
  const componentTypeConfigs = useProjectStore(
    (state) => state.componentTypeConfigs,
  );
  const components = useProjectStore((state) => state.components);
  const inputs = useProjectStore((state) => state.inputs);
  const addResult = useProjectStore((state) => state.addResult);
  const lastResult = useLastResult();
  const [runLoading, setRunLoading] = useState(false);

  if (!activeProject) return <div>Cannot display empty project.</div>;

  const onRun = async () => {
    if (!activeProject) return;
    setRunLoading(true);
    try {
      const result = await postProjectsByProjectIdRun({
        path: { project_id: activeProject.id },
        body: inputs,
      });
      if (result.data) {
        addResult(result.data);
      }
    } catch (err) {
      console.error("Cannot run", activeProject.id);
    } finally {
      setRunLoading(false);
    }
  };

  return (
    <div className="gap-3 flex flex-col">
      {lastResult && (
        <p className="text-sm text-muted-foreground">
          Last Run: {fromNowSecs(lastResult.result.created_at)} in{" "}
          {getDuration(lastResult)}s
        </p>
      )}
      {activeProject.graph.items
        .map((item) => components.find((c) => c.id === item.component_id))
        .filter(
          (component) =>
            component &&
            componentTypeConfigs.component_type_input.includes(
              component?.configuration.component_type,
            ),
        )
        .map((component) => {
          if (!component) return;
          return <ComponentDisplay key={component.id} component={component} />;
        })}
      <Button disabled={runLoading} size="sm" onClick={onRun}>
        {runLoading ? <Loader2 className="animate-spin" /> : <PlayIcon />} Run
      </Button>
      {activeProject.graph.items
        .map((item) => components.find((c) => c.id === item.component_id))
        .filter(
          (component) =>
            component &&
            componentTypeConfigs.component_type_output.includes(
              component?.configuration.component_type,
            ),
        )
        .map((component) => {
          if (!component) return;
          return <ComponentDisplay key={component.id} component={component} />;
        })}
    </div>
  );
};

export default ProjectDisplay;
