import { Skeleton } from "components/ui/skeleton";
import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Blocks, ClockIcon } from "lucide-react";

import useProjectStore, { useLoadProject } from "storage/ProjectStore";
import ProjectDisplay from "components/ProjectDisplay";
import ProjectFlow from "components/ProjectFlow";
import ProjectPublish from "components/ProjectPublish";
import { fromNowSecs } from "lib/time";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { Button } from "components/ui/button";
import { Pencil1Icon } from "@radix-ui/react-icons";
import {
  postProjectsByProjectIdCreateDraft,
  postProjectsByProjectIdRestore,
} from "client";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import ProjectRuns from "components/ProjectRuns";
import ProjectVersions from "components/ProjectVersions";
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import ProjectIntegration from "components/ProjectIntegration";
import SecurePageWrapper from "components/SecurePageWrapper";

const ProjectPageWrapper = () => {
  return (
    <SecurePageWrapper>
      <ProjectPage />
    </SecurePageWrapper>
  );
};

function ProjectPage() {
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { loading } = useLoadProject(projectId as string);
  const project = useProjectStore((state) => state.activeProject);
  const mainProject = useProjectStore((state) => state.mainProject);
  const [editLoading, setEditLoading] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const isCurrentVersion = mainProject?.active_project_id === project?.id;
  const isPreview = searchParams.get("preview") === "true";

  const restore = async () => {
    if (!project) return;
    if (isCurrentVersion) return;
    setRestoring(true);

    const data = await postProjectsByProjectIdRestore({
      path: {
        project_id: project.id,
      },
    });

    if (data.data) {
      if (window.top) {
        window.top.location.replace(`/projects/${project.id}`);
      } else {
        window.location.replace(`/projects/${data.data.id}`);
      }
    }
    setRestoring(false);
  };

  const edit = async () => {
    if (!project) return;
    setEditLoading(true);

    const data = await postProjectsByProjectIdCreateDraft({
      path: {
        project_id: project.id,
      },
    });

    if (data.data) {
      navigate(`/projects/${data.data.id}/draft`);
    }

    setEditLoading(false);
  };

  if (!project || loading) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  return (
    <div className="w-full h-screen flex flex-col p-2 overflow-hidden">
      {!isCurrentVersion && (
        <Alert className="bg-gray-800 text-white">
          <AlertTitle>Heads up!</AlertTitle>
          <AlertDescription>
            You're viewing old version.{" "}
            <a
              href={`/projects/${mainProject?.active_project_id}`}
              target="_blank"
            >
              <Button variant="link">View Active Version</Button>
            </a>
          </AlertDescription>
        </Alert>
      )}
      <div className="flex items-center">
        {!isPreview && (
          <div className="p-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild className="cursor-pointer">
                  <a href="/">
                    <Blocks size={35} />
                  </a>
                </TooltipTrigger>
                <TooltipContent className="bg-black text-white border-0">
                  <p>Home - Projects</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
        <div className="flex-1">
          <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight first:mt-0">
            {project.name}
          </h2>
          <p className="text-sm text-muted-foreground">
            Hien Nguyen ・ {fromNowSecs(project.created_at)}
          </p>
        </div>
        {!isPreview && (
          <div className="flex gap-1">
            <Button
              size="sm"
              variant="outline"
              onClick={edit}
              disabled={editLoading}
            >
              <Pencil1Icon /> Edit
            </Button>
            <ProjectPublish />
          </div>
        )}
        {isPreview && !isCurrentVersion && (
          <div className="flex gap-1">
            <Button size="sm" onClick={restore} disabled={restoring}>
              <ClockIcon /> {restoring ? "Restoring..." : "Restore"}
            </Button>
          </div>
        )}
      </div>
      <div className="pt-4 pb-4">
        <p className="text-sm">{project.description}</p>
      </div>
      <Tabs
        defaultValue="general"
        className="w-full flex flex-col flex-grow-1 flex-1 h-full overflow-hidden"
      >
        <TabsList style={{ width: "fit-content" }}>
          <TabsTrigger value="general">Flow & Display</TabsTrigger>
          <TabsTrigger value="runs">Runs</TabsTrigger>
          {!isPreview && (
            <TabsTrigger value="versions">History versions</TabsTrigger>
          )}
          <TabsTrigger value="integration">
            Integration (Embed & API)
          </TabsTrigger>
        </TabsList>
        <TabsContent value="general" asChild>
          <div className="w-full flex flex-grow-1 flex-1 h-full overflow-hidden">
            <div className="w-full flex-grow-1 flex-1 border overflow-hidden">
              <ProjectFlow project={project} readOnly />
            </div>
            <div className="w-full flex-grow-1 flex-1 p-5 border overflow-auto">
              <ProjectDisplay />
            </div>
          </div>
        </TabsContent>
        <TabsContent value="runs">
          <div className="w-full flex flex-grow-1 flex-1 h-full">
            <ProjectRuns />
          </div>
        </TabsContent>
        <TabsContent value="versions">
          <div className="w-full flex flex-grow-1 flex-1 h-full">
            <ProjectVersions />
          </div>
        </TabsContent>
        <TabsContent value="integration" asChild>
          <ProjectIntegration />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default ProjectPageWrapper;
