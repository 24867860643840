import { ProjectResultResponse } from "client";
import Status from "components/status";
import { Button } from "components/ui/button";
import { Drawer, DrawerContent, DrawerTrigger } from "components/ui/drawer";
import { TableCell, TableRow } from "components/ui/table";
import { shortId } from "lib/id";
import { getDuration, getStartAt, isResultSuccess } from "lib/project_result";
import { formatSecs } from "lib/time";
import useProjectStore from "storage/ProjectStore";

const ProjectRunItem = ({ item }: { item: ProjectResultResponse }) => {
  const isSuccess = isResultSuccess(item);
  const startAt = getStartAt(item);
  const duration = getDuration(item);
  const mainProject = useProjectStore((state) => state.mainProject);
  const isCurrentVersion =
    item.result.project_id === mainProject?.active_project_id;
  const resultDetailUrl = `/projects/${item.result.project_id}/reviewResult?resultId=${item.result.id}`;
  const previewProjectUrl = `/projects/${item.result.project_id}?preview=true`;

  return (
    <TableRow>
      <TableCell className="font-medium">
        <Drawer>
          <DrawerTrigger asChild>
            <Button size="sm" variant="link" className="p-0">
              #{shortId(item.result.id)}
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[95vh] p-0">
            <iframe src={resultDetailUrl} className="w-full h-full" />
          </DrawerContent>
        </Drawer>
      </TableCell>
      <TableCell>{item.result.run_count}</TableCell>
      <TableCell>
        <Status isSuccess={isSuccess} />
      </TableCell>
      <TableCell>{formatSecs(startAt)}</TableCell>
      <TableCell>{duration}s</TableCell>
      <TableCell>
        <Drawer>
          <DrawerTrigger asChild>
            <Button size="sm" variant="link" className="p-0">
              #{shortId(item.result.project_id)} (
              {isCurrentVersion ? "Current" : "Old"})
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[95vh] p-0">
            <iframe src={previewProjectUrl} className="w-full h-full" />
          </DrawerContent>
        </Drawer>
      </TableCell>
    </TableRow>
  );
};

export default ProjectRunItem;
