import { uploadFile } from "lib/upload";
import { CircleStop, FileIcon, Loader2, PlayIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { File as FileRes, postFilesByFileIdDownload } from "client";

export type AudioRecordingProps = {
  onCompleteFile: (file: FileRes) => void;
  readOnly?: boolean;
  fileRes?: FileRes;
};

const AudioRecording = ({
  onCompleteFile,
  readOnly,
  fileRes: fileResDefault,
}: AudioRecordingProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const mediaRecorder = useRef<MediaRecorder>();
  const stream = useRef<MediaStream>();
  const recordedChunks = useRef<Blob[]>([]);
  const [fileRes, setFileRes] = useState<FileRes | undefined>();
  const [failedMessage, setFailedMessage] = useState<string | undefined>();

  useEffect(() => {
    if (fileResDefault && fileResDefault.id !== fileRes?.id) {
      setFileRes(fileResDefault);
    }
  }, [fileResDefault]);

  const onComplete = async () => {
    if (!recordedChunks.current.length || readOnly) return;
    setIsUploading(true);
    const type = recordedChunks.current[0]?.type.split(";")[0] || "audio/webm";
    const recordingFile = new File(recordedChunks.current, "recording", {
      type,
      lastModified: new Date().getTime(),
    });
    console.log("Recording File", type, recordingFile);

    const file = await uploadFile(recordingFile);
    if (file instanceof Error) {
      console.error("Cannot upload file");
      setFailedMessage(file.message);
    } else {
      setFileRes(file as FileRes);
      onCompleteFile(file as FileRes);
    }

    recordedChunks.current = [];
    setIsUploading(false);
  };

  const onStartRecording = async () => {
    if (mediaRecorder.current || stream.current || isUploading) return;
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      mediaRecorder.current = new MediaRecorder(stream.current);

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstart = () => {
        console.log("Start Voice Recording");
        setIsRecording(true);
      };

      mediaRecorder.current.onstop = () => {
        console.log("Stop Voice Recording");
        onComplete();
        setIsRecording(false);
      };

      mediaRecorder.current.start();
    } catch (err: any) {
      console.error("Cannot start voice recording", err);
      setFailedMessage(err.message);
    }
  };

  const onStopRecording = () => {
    if (mediaRecorder.current && stream.current) {
      mediaRecorder.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      mediaRecorder.current = undefined;
      stream.current = undefined;
    } else {
      console.warn("Media recording is undefined while stopping");
    }
  };

  const onClickPreview = async () => {
    if (!fileRes) return;
    const downloadRes = await postFilesByFileIdDownload({
      path: {
        file_id: fileRes.id,
      },
    });

    if (downloadRes.data) {
      window.open(downloadRes.data, "_blank");
    }
  };

  return (
    <>
      <div
        className={
          "flex flex-col items-center justify-center w-full p-6 border-2 border-dashed rounded-lg"
        }
      >
        <div className="flex flex-col gap-2">
          {isRecording && (
            <CircleStop
              size={50}
              color="red"
              className="cursor-pointer hover:bg-gray-200 p-2 rounded-full"
              onClick={onStopRecording}
            />
          )}
          {!isRecording && (
            <PlayIcon
              size={50}
              className="cursor-pointer hover:bg-gray-200 p-2 rounded-full"
              onClick={onStartRecording}
            />
          )}
        </div>
        {isRecording && (
          <small className="text-sm font-medium leading-none">
            Recording...
          </small>
        )}
        {!isRecording && (
          <small className="text-sm font-medium leading-none">
            Click to start recording
          </small>
        )}
        {isUploading && (
          <small className="text-sm font-medium leading-none">
            Uploading...
          </small>
        )}
        {failedMessage && (
          <small className="text-sm font-medium leading-none text-red-500">
            {failedMessage}
          </small>
        )}
      </div>
      <div className="w-full flex flex-col gap-2">
        {fileRes && (
          <div>
            <div
              className="flex gap-2 pt-2 pb-2 items-center cursor-pointer"
              onClick={onClickPreview}
            >
              {isUploading && <Loader2 className="animate-spin" />}
              {!isUploading && <FileIcon />}
              <small className="text-sm font-medium leading-none">
                recording
              </small>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AudioRecording;
