import { Skeleton } from "components/ui/skeleton";
import React from "react";
import { useParams } from "react-router-dom";
import useProjectStore, { useLoadProject } from "storage/ProjectStore";
import ProjectDisplay from "components/ProjectDisplay";
import { Separator } from "components/ui/separator";

function ProjectAppViewPage() {
  const { projectId } = useParams();
  const { loading } = useLoadProject(projectId as string);
  const project = useProjectStore((state) => state.activeProject);

  if (!project || loading) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  return (
    <div className="w-full flex flex-col p-5">
      <div className="w-[95%] md:w-[60%] mx-auto">
        <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
          {project.name}
        </h2>
        <p className="text-sm text-muted-foreground">{project.description}</p>
        <Separator className="mb-5 mt-5" />
        <ProjectDisplay />
      </div>
    </div>
  );
}

export default ProjectAppViewPage;
