import { Blocks } from "lucide-react";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "components/ui/sidebar";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Button } from "./ui/button";
import useAuthStore from "storage/AuthStore";
import useUIStore from "storage/UIStore";
import { useState } from "react";
import { postAuthLogout } from "client";

// Menu items.
const items = [
  {
    title: "Projects",
    url: "/",
    icon: Blocks,
  },
];

export function AppSidebar() {
  const [open, setOpen] = useState(false);
  const menuCollapse = useUIStore((state) => state.menuCollapse);
  const user = useAuthStore((state) => state.user);

  const logOut = async () => {
    await postAuthLogout();
    window.location.href = "/";
  };

  return (
    <Sidebar collapsible="icon">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Ez AI</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <a href={item.url}>
                      <item.icon />
                      <span>{item.title}</span>
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <>
              {!menuCollapse && (
                <div
                  className="flex gap-2 items-center bg-gray-200 rounded p-1 cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  <Avatar>
                    <AvatarImage src={""} alt="" />
                    <AvatarFallback>
                      {user?.name.charAt(0) || "E"}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <small className="text-sm font-medium leading-none truncate">
                      {user?.name}
                    </small>
                    <p className="text-sm text-muted-foreground truncate">
                      {user?.email}
                    </p>
                  </div>
                </div>
              )}
              {menuCollapse && (
                <Avatar
                  className="w-6 h-6 cursor-pointer p-4 bg-gray-300"
                  onClick={() => setOpen(true)}
                >
                  <AvatarImage src="" alt="" color="red" />
                  <AvatarFallback>{user?.name.charAt(0) || "E"}</AvatarFallback>
                </Avatar>
              )}
            </>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{user?.name}</DialogTitle>
              <DialogDescription>Manage your profile</DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="destructive" onClick={logOut}>
                Logout
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </SidebarFooter>
    </Sidebar>
  );
}
