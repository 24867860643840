import * as React from "react";

import {
  Component,
  EzEngineValue,
  ImageOutputAttributes,
  postFilesByFileIdDownload,
} from "client";
import { useLastResult } from "storage/ProjectStore";
import { Separator } from "components/ui/separator";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

export const getImageFiles = (value: EzEngineValue): string[] => {
  const res: string[] = [];

  if (value.type === "Image") {
    res.push(value.file_id);
  } else if (value.type === "Multiples") {
    for (const innerVal of Object.values(value.inputs)) {
      const fileIds = getImageFiles(innerVal);
      res.push(...fileIds);
    }
  }

  return res;
};

export type ImageOutputComponentDisplayProps = {
  component: Component;
  preview?: boolean;
};

export const ImageOutputComponentDisplay: React.FC<
  ImageOutputComponentDisplayProps
> = ({ component }) => {
  const [imageUrls, setImages] = React.useState<string[]>([]);
  const lastResult = useLastResult();
  const output = lastResult?.components?.find(
    (c) => c.component_id === component.id,
  )?.output;

  React.useEffect(() => {
    if (output) {
      const fileIds = getImageFiles(output.result);
      if (fileIds.length > 0) {
        load(fileIds);
      }
    }
  }, [output]);

  const load = async (fileIds: string[]) => {
    const results = await Promise.all(
      fileIds.map(async (fileId) => {
        const downloadRes = await postFilesByFileIdDownload({
          path: {
            file_id: fileId,
          },
        });
        return downloadRes;
      }),
    );

    setImages(
      results
        .filter((result) => !!result.data)
        .map((result) => result.data) as string[],
    );
  };

  const label =
    (component.configuration.attributes as ImageOutputAttributes).label ||
    component.component_name;

  return (
    <div className="grid w-full gap-1.5">
      <Separator className="my-4" />
      <div className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        {label}
      </div>
      <div className="w-full">
        <PhotoProvider>
          <div>
            {imageUrls.map((item, index) => (
              <PhotoView key={index} src={item}>
                <img src={item} alt="" />
              </PhotoView>
            ))}
          </div>
        </PhotoProvider>
      </div>
    </div>
  );
};
