import * as React from "react";
import AuthenticationPage from "./Auth";

import { AuthStepState, useLoadUser } from "storage/AuthStore";
import { Loader2 } from "lucide-react";

export type SecurePageWrapperProps = {
  children: React.ReactNode;
};

const SecurePageWrapper = ({ children }: SecurePageWrapperProps) => {
  const { authStep } = useLoadUser();

  if (authStep === AuthStepState.Loading) {
    return (
      <div>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Verifying...
      </div>
    );
  }

  if (authStep == AuthStepState.UnAuthenticate) {
    return <AuthenticationPage />;
  }

  return <>{children}</>;
};

export default SecurePageWrapper;
