import { Skeleton } from "components/ui/skeleton";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  getMainProjectsByMainProjectId,
  putProjectsByProjectId,
  postProjectsByDraftProjectIdMergeDraft,
} from "client";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import { Loader2, Check } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";

import useProjectStore, { useLoadProject } from "storage/ProjectStore";
import ProjectFlow from "components/ProjectFlow";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import ViewProjectAppDrawer from "components/ViewProjectAppDrawer";
import SecurePageWrapper from "components/SecurePageWrapper";

const debounceUpdateProject = debounce(putProjectsByProjectId, 300);

const EditProjectPageWrapper = () => {
  return (
    <SecurePageWrapper>
      <EditProjectPage />
    </SecurePageWrapper>
  );
};

function EditProjectPage() {
  const { projectId } = useParams();
  const { loading } = useLoadProject(projectId as string);
  const [merging, setMerging] = useState(false);
  const navigate = useNavigate();
  const project = useProjectStore((state) => state.activeProject);
  const setActiveProject = useProjectStore((state) => state.setActiveProject);

  const updateProject = () => {
    if (!project) return;

    debounceUpdateProject({
      path: {
        project_id: project.id,
      },
      body: {
        name: project.name,
        description: project.description,
        graph: project.graph,
      },
    });
  };

  const updateName = async (name: string) => {
    if (!project) return;

    project.name = name;
    updateProject();
    setActiveProject({ ...project });
  };

  const updateDescription = async (description: string) => {
    if (!project) return;

    project.description = description;
    updateProject();
    setActiveProject({ ...project });
  };

  const saveAndExit = async () => {
    if (!project || !project.main_project_id) return;
    const data = await getMainProjectsByMainProjectId({
      path: {
        main_project_id: project.main_project_id,
      },
    });

    if (data.data) {
      navigate(`/projects/${data.data.active_project_id}`);
    }
  };

  const mergeToMain = async () => {
    if (!project || !project.main_project_id) return;
    setMerging(true);
    const data = await postProjectsByDraftProjectIdMergeDraft({
      path: {
        draft_project_id: project.id,
      },
    });

    if (data.data) {
      navigate(`/projects/${project.id}`);
    }

    setMerging(false);
  };

  if (!project || loading) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  return (
    <div className="w-full flex flex-col p-2 h-screen">
      <div className="flex items-center">
        <div className="flex-1">
          <input
            className="scroll-m-20 text-2xl font-semibold tracking-tight first:mt-0 w-full"
            value={project.name}
            onChange={(e) => updateName(e.currentTarget.value)}
            placeholder="Typing project name..."
            autoFocus
          />
          <p className="text-sm text-muted-foreground">
            Edits will be saved automatically
          </p>
        </div>
        <div className="flex gap-1">
          <ViewProjectAppDrawer projectId={project.id}>
            <Button size="sm" variant="outline">
              <EyeOpenIcon /> Preview & Test
            </Button>
          </ViewProjectAppDrawer>
          <Button size="sm" variant="outline" onClick={saveAndExit}>
            Save as draft
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button size="sm" disabled={merging}>
                {merging ? <Loader2 className="animate-spin" /> : <Check />}{" "}
                Finish
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will promote the draft
                  version to the current running version.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={mergeToMain}>
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <div className="pt-4 pb-4">
        <Textarea
          rows={3}
          value={project.description}
          onChange={(e) => updateDescription(e.currentTarget.value)}
          placeholder="Typing project description..."
        />
      </div>
      <div className="w-full flex flex-grow-1 flex-1">
        <div className="w-full flex-grow-1 flex-1 border">
          <ProjectFlow project={project} />
        </div>
      </div>
    </div>
  );
}

export default EditProjectPageWrapper;
