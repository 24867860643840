import { Component } from "client";
import { InnerTextInputNode } from "./TextInputNode";
import { InnerTextOutputNode } from "./TextOutputNode";
import { InnerAIChatNode } from "./AIChatNode";
import { InnerImageInputNode } from "./ImageInputNode";
import { InnerAudioInputNode } from "./AudioInputNode";
import { InnerSpeechToTextNode } from "./SpeechToTextNode";
import { InnerTextToSpeechNode } from "./TextToSpeechNode";
import { InnerAudioOutputNode } from "./AudioOutputNode";
import { InnerTextToImageNode } from "./TextToImageNode";
import { InnerImageOutputNode } from "./ImageOutputNode";

export type InnerComponentPreviewProps = {
  component: Component;
  onClick?: (component: Component) => void | Promise<void>;
};

const InnerComponentPreview = ({
  component,
  onClick,
}: InnerComponentPreviewProps) => {
  const click = () => {
    if (onClick) onClick(component);
  };

  if (component.configuration.component_type === "TextInput") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerTextInputNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "ImageInput") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerImageInputNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "TextOutput") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerTextOutputNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "AIChat") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerAIChatNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "SpeechToText") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerSpeechToTextNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "TextToSpeech") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerTextToSpeechNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "TextToImage") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerTextToImageNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "AudioInput") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerAudioInputNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "AudioOutput") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerAudioOutputNode data={component} />
      </div>
    );
  }

  if (component.configuration.component_type === "ImageOutput") {
    return (
      <div
        key={component.id}
        className="hover:scale-105 transition-all duration-200"
        onClick={click}
      >
        <InnerImageOutputNode data={component} />
      </div>
    );
  }

  return <></>;
};

export default InnerComponentPreview;
