import { ProjectResultResponse } from "client";

export const isResultSuccess = (result: ProjectResultResponse) =>
  !result.components.some((component) => !component.is_success);

export const getStartAt = (result: ProjectResultResponse) =>
  Math.min(...result.components.map((component) => component.start_at));

export const getEndAt = (result: ProjectResultResponse) =>
  Math.max(...result.components.map((component) => component.end_at));

export const getDuration = (result: ProjectResultResponse) =>
  getEndAt(result) - getStartAt(result);
