import { CopyIcon } from "@radix-ui/react-icons";
import { Component, ProjectResultResponse } from "client";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import config from "config";
import ReactJson from "react-json-view";
import Markdown from "react-markdown";
import useProjectStore from "storage/ProjectStore";

// WARNING: Refactor API part
const ProjectIntegration = () => {
  const activeProject = useProjectStore((state) => state.activeProject);
  const currentComponents = useProjectStore((state) => state.components);
  const componentTypeConfigs = useProjectStore(
    (state) => state.componentTypeConfigs,
  );
  const embedUrl = `${window.origin}/projects/${activeProject?.id}/app?embed=true`;
  const embed = `<iframe
  src="${embedUrl}"
  title="Ez AI - ${activeProject?.name}"
  width="100%"
  height="500px">
</iframe>`;

  let embedCode = "```html \n";
  embedCode += embed;
  embedCode += "\n";
  embedCode += "``` \n";

  const copyEmbedCode = () => {
    navigator.clipboard.writeText(embed);
  };

  const apiEndpoint = `${config.apiServerUrl}/projects/${activeProject?.id}/run`;
  const inputComponents =
    (activeProject?.graph.items
      .map((item) => currentComponents.find((c) => c.id === item.component_id))
      .filter(
        (c) =>
          c &&
          componentTypeConfigs.component_type_input.includes(
            c.configuration.component_type,
          ),
      ) as Component[]) || [];
  const body = {};
  inputComponents.forEach((inputComponent) => {
    // Currently, we just support input is string
    body[inputComponent.component_name] = `${inputComponent.component_name}`;
  });
  const response: ProjectResultResponse = {
    result: {
      id: "Uuid",
      project_id: activeProject?.id || "",
      run_count: 1,
      updated_at: 0,
      created_at: 0,
      runner_id: "Uuid",
    },
    components: currentComponents.map((component) => ({
      id: "Uuid",
      result_id: "Uuid",
      component_id: component.id,
      is_success: true,
      input: {
        input: {
          type: "String",
          value: "Input Value",
        },
      },
      output: {
        result: {
          type: "String",
          value: "Output Value",
        },
      },
      updated_at: 0,
      created_at: 0,
      start_at: 0,
      end_at: 0,
    })),
  };

  const curlCode = `
\`\`\`bash
curl -X POST ${config.apiServerUrl}/projects/${activeProject?.id}/run \\
  -H "Content-Type: application/json" \\
  -d '${JSON.stringify(body, null, 4)}'
\`\`\`
  `;

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <p className="text-sm text-muted-foreground">
        You can integrate project into your application by Embed or API.
      </p>
      <div className="w-full flex flex-1 h-full overflow-hidden">
        <div className="w-full flex-grow-1 flex-1 border overflow-hidden p-3">
          <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
            Embed via Iframe
          </h3>
          <p className="text-sm text-muted-foreground pb-2">
            You can embed the project app view by copying the embed code below
            and pasting it into your website (HTML).
            <br />
            You can try this embed in{" "}
            <a
              href="https://www.w3schools.com/tags/tryit.asp?filename=tryhtml_iframe"
              target="_blank"
              className="text-black"
            >
              https://www.w3schools.com/tags/tryit.asp?filename=tryhtml_iframe
            </a>
          </p>
          <div className="prose">
            <Markdown>{embedCode}</Markdown>
          </div>
          <div className="flex gap-2 pt-2">
            <Button onClick={copyEmbedCode}>
              <CopyIcon /> Copy Embed Code
            </Button>
          </div>
        </div>
        <div className="w-full h-full flex-1 overflow-auto border p-3 pb-5">
          <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
            API
          </h3>
          <p className="text-sm text-muted-foreground pb-2">
            You can integrate the project by call API into our platform
          </p>
          <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
            Request
          </h4>
          <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
            <li>
              Endpoint:{" "}
              <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                {apiEndpoint}
              </code>
            </li>
            <li>
              Method:{" "}
              <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                POST
              </code>
            </li>
            <li>
              Headers: <br />
              <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                api-key: [EZ AI API KEY]
              </code>
            </li>
            <li>
              Body (JSON): <ReactJson name={false} src={body} />
            </li>
          </ul>
          <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
            Response
          </h4>
          <ReactJson name={false} src={response} />
          <Separator className="w-full mt-5 mb-5" />
          <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
            Curl Example
          </h4>
          <div className="prose">
            <Markdown>{curlCode}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectIntegration;
