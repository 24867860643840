import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const convertSecondsToDay = (secs: number) => {
  return dayjs.unix(secs);
};

export const fromNowSecs = (secs: number) => {
  return convertSecondsToDay(secs).fromNow();
};

export const formatSecs = (secs: number) => {
  return convertSecondsToDay(secs).format("D MMM YYYY HH:mm:ss");
};
