import { create } from "zustand";
import { getMe, User } from "client";
import { useEffect } from "react";

export enum AuthStepState {
  Loading,
  Authenticate,
  UnAuthenticate,
}

type AuthState = {
  user?: User;
  setUser: (user?: User) => void;
  accessToken?: string;
  setAccessToken: (accessToken?: string) => void;
  authStep: AuthStepState;
  setAuthStep: (authStep: AuthStepState) => void;
};

const useAuthStore = create<AuthState>((set, get) => ({
  user: undefined,
  setUser: (user) => set({ user }),
  accessToken: undefined,
  setAccessToken: (accessToken) => {
    set({ accessToken });
  },
  authStep: AuthStepState.Loading,
  setAuthStep: (authStep: AuthStepState) => set({ authStep }),
}));

export default useAuthStore;

export const useLoadUser = () => {
  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.setUser);
  const setAuthStep = useAuthStore((state) => state.setAuthStep);
  const authStep = useAuthStore((state) => state.authStep);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    let userRes = await getMe();
    if (userRes.data) {
      setUser(userRes.data);
      setAuthStep(AuthStepState.Authenticate);
    } else {
      setAuthStep(AuthStepState.UnAuthenticate);
    }
  };

  return {
    user,
    authStep,
  };
};
