import { deleteComponentsByComponentId, putProjectsByProjectId } from "client";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { DrawerFooter, DrawerClose } from "components/ui/drawer";
import { useState } from "react";
import useProjectStore from "storage/ProjectStore";

export type NodeFooterProps = {
  componentId: string;
  onSave: () => Promise<void>;
  readOnly?: boolean;
  setOpen?: (open: boolean) => void;
};

const NodeFooter = ({
  componentId,
  setOpen,
  readOnly,
  onSave,
}: NodeFooterProps) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const activeProject = useProjectStore((state) => state.activeProject);
  const setActiveProject = useProjectStore((state) => state.setActiveProject);
  const removeComponent = useProjectStore((state) => state.removeComponent);

  const _onSave = async () => {
    setSaving(true);
    await onSave();
    setSaving(false);
  };

  const onDelete = async () => {
    if (!activeProject) return;
    setDeleting(true);

    const data = await deleteComponentsByComponentId({
      path: {
        component_id: componentId,
      },
    });
    if (data.data) {
      removeComponent(componentId);
      // Remove item
      const graph = activeProject.graph;
      graph.items = graph.items.filter(
        (item) => item.component_id !== componentId,
      );

      // Remove next component ids
      graph.items.map((item) => {
        item.next_component_ids = item.next_component_ids.filter(
          (nextComponentId) => nextComponentId !== componentId,
        );
        return item;
      });
      activeProject.graph = graph;

      // Update
      await putProjectsByProjectId({
        path: {
          project_id: activeProject.id,
        },
        body: {
          name: activeProject.name,
          description: activeProject.description,
          graph: activeProject.graph,
        },
      });
      setActiveProject({ ...activeProject });

      if (setOpen) setOpen(false);
    }

    setDeleting(false);
  };

  return (
    <DrawerFooter>
      {!readOnly && (
        <Button onClick={_onSave} disabled={saving}>
          {saving ? "Saving..." : "Save"}
        </Button>
      )}
      {!readOnly && (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="destructive" disabled={deleting}>
              {deleting ? "Removing..." : "Remove"}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={onDelete}>
                Continue Remove
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
      <DrawerClose className="w-full">
        <Button className="w-full" variant="outline">
          Close
        </Button>
      </DrawerClose>
    </DrawerFooter>
  );
};

export default NodeFooter;
