import { useLastResult } from "storage/ProjectStore";
import ComponentResultItemCard from "./ComponentResultItemCard";

const ComponentResultList = () => {
  const result = useLastResult();
  if (!result) return <></>;

  result.components.sort((a, b) => a.start_at - b.start_at);
  return (
    <div className="gap-5 flex flex-col pt-3">
      {result.components.map((componentResult) => (
        <ComponentResultItemCard
          key={componentResult.component_id}
          componentResult={componentResult}
        />
      ))}
    </div>
  );
};

export default ComponentResultList;
