import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";

export type ComponentLogoProps = {
  src?: string;
  text?: string;
};

export function ComponentLogo({ src, text }: ComponentLogoProps) {
  return (
    <Avatar>
      <AvatarImage src={src} alt={`@${text}`} />
      <AvatarFallback>{text}</AvatarFallback>
    </Avatar>
  );
}
