import { Project } from "client";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Drawer, DrawerContent, DrawerTrigger } from "components/ui/drawer";
import { TableCell, TableRow } from "components/ui/table";
import { shortId } from "lib/id";
import { formatSecs } from "lib/time";
import useProjectStore from "storage/ProjectStore";

const ProjectVersionRow = ({ item }: { item: Project }) => {
  const currentProjectId = useProjectStore((state) => state.activeProject?.id);
  const isCurrentVersion = item.id === currentProjectId;
  const results = useProjectStore((state) => state.results);
  const versionResults = results.filter(
    (result) => result.result.project_id === item.id,
  );
  const previewProjectUrl = `/projects/${item.id}?preview=true`;

  return (
    <TableRow>
      <TableCell className="font-medium">
        <Drawer>
          <DrawerTrigger asChild>
            <Button size="sm" variant="link" className="p-0">
              #{shortId(item.id)}
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[95vh] p-0">
            <iframe
              src={previewProjectUrl}
              className="w-full h-full"
              allow="top-navigation"
            />
          </DrawerContent>
        </Drawer>
      </TableCell>
      <TableCell>
        {isCurrentVersion ? (
          <Badge>Current</Badge>
        ) : (
          <Badge variant="outline">Old</Badge>
        )}
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{formatSecs(item.created_at)}</TableCell>
      <TableCell>{versionResults.length}</TableCell>
    </TableRow>
  );
};

export default ProjectVersionRow;
