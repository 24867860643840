import { client } from "client/services.gen";
import config from "config";

export const init = () => {
  client.setConfig({
    baseURL: config.apiServerUrl,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
};
