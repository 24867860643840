import * as React from "react";

import { cn } from "lib/utils";
import { GoogleLogin } from "@react-oauth/google";
import { postAuthLoginWithGg } from "client";
import useAuthStore from "storage/AuthStore";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const setAccessToken = useAuthStore((state) => state.setAccessToken);

  const onSuccess = async (idToken: string) => {
    setIsLoading(true);
    const res = await postAuthLoginWithGg({
      body: idToken,
    });

    if (res.data) {
      setAccessToken(res.data.access_token);
      window.location.reload();
    }

    setIsLoading(false);
  };

  return (
    <div className={cn("grid gap-6 justify-center", className)} {...props}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse.credential || "");
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </div>
  );
}
