import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Projects from "pages/Projects";
import Project from "pages/Project";
import NotFound from "pages/NotFound";
import { init } from "client/config";
import ProjectAppViewPage from "pages/ProjectAppView";
import EditProjectPage from "pages/EditProject";
import ProjectReviewResultPage from "pages/ProjectResultReview";
import { useEffect } from "react";
import Clarity from "@microsoft/clarity";
import useAuthStore from "storage/AuthStore";

init();

export default () => {
  useEffect(() => {
    const userId = useAuthStore.getState().user?.id;
    if (userId) Clarity.identify(userId);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path="/projects/:projectId" element={<Project />} />
        <Route
          path="/projects/:projectId/app"
          element={<ProjectAppViewPage />}
        />
        <Route
          path="/projects/:projectId/reviewResult"
          element={<ProjectReviewResultPage />}
        />
        <Route
          path="/projects/:projectId/draft"
          element={<EditProjectPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
