import { Badge } from "./ui/badge";

const Status = ({ isSuccess }: { isSuccess: boolean }) => {
  return (
    <Badge variant={isSuccess ? "default" : "destructive"}>
      {isSuccess ? "Success" : "Failure"}
    </Badge>
  );
};

export default Status;
