import { PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "./ui/button";
import useProjectStore from "storage/ProjectStore";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import InnerComponentPreview from "./ProjectFlow/nodes/InnerComponentPreview";
import { Component, postComponents, putProjectsByProjectId } from "client";
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { ScrollArea } from "./ui/scroll-area";

export enum ConnectType {
  INPUT,
  OUTPUT,
}

export type CreateNodeConnectProps = {
  componentId: string;
  connectType: ConnectType;
};

const CreateNodeConnect = ({
  componentId,
  connectType,
}: CreateNodeConnectProps) => {
  const [open, setOpen] = useState(false);
  const activeProject = useProjectStore((state) => state.activeProject);
  const componentTypeConfigs = useProjectStore(
    (state) => state.componentTypeConfigs,
  );
  const setActiveProject = useProjectStore((state) => state.setActiveProject);
  const setComponent = useProjectStore((state) => state.setComponent);
  const currentComponents = useProjectStore((state) => state.components);
  const availableCurrentComponents = currentComponents.filter(
    (currentComponent) => {
      if (currentComponent.id === componentId) return false;

      if (connectType === ConnectType.INPUT) {
        if (
          componentTypeConfigs.component_type_output.includes(
            currentComponent.configuration.component_type,
          )
        )
          return false;
      }

      if (connectType === ConnectType.OUTPUT) {
        if (
          componentTypeConfigs.component_type_input.includes(
            currentComponent.configuration.component_type,
          )
        )
          return false;
      }
      // Cannot be existing input
      const currentItem = activeProject?.graph.items.find(
        (item) => item.component_id === currentComponent.id,
      );
      if (currentItem?.next_component_ids.includes(componentId)) return false;

      // Cannot be existing output
      const item = activeProject?.graph?.items?.find(
        (item) => item.component_id === componentId,
      );
      if (item?.next_component_ids?.includes(currentComponent.id)) return false;

      return true;
    },
  );

  const basedComponents = useProjectStore((state) => state.basedComponents);
  const availableComponents = basedComponents.filter((component) => {
    if (connectType === ConnectType.INPUT) {
      return !componentTypeConfigs.component_type_output.includes(
        component.configuration.component_type,
      );
    }
    return !componentTypeConfigs.component_type_input.includes(
      component.configuration.component_type,
    );
  });

  const onClickComponent = async (component: Component) => {
    if (!activeProject) return;
    const data = await postComponents({
      body: {
        component_name: component.component_name,
        configuration: component.configuration,
      },
    });

    if (data.data) {
      setComponent(data.data);
      if (connectType === ConnectType.INPUT) {
        activeProject.graph.items.push({
          component_id: data.data.id,
          next_component_ids: [componentId],
        });
      } else {
        activeProject.graph.items.push({
          component_id: data.data.id,
          next_component_ids: [],
        });
        const parentComponent = activeProject.graph.items.find(
          (item) => item.component_id === componentId,
        );
        if (parentComponent) {
          parentComponent.next_component_ids.push(data.data.id);
        }
      }
      await putProjectsByProjectId({
        path: {
          project_id: activeProject.id,
        },
        body: {
          name: activeProject.name,
          description: activeProject.description,
          graph: activeProject.graph,
        },
      });
      setActiveProject({ ...activeProject });
      setOpen(false);
    }
  };

  const onClickCurrentComponent = async (currentComponent: Component) => {
    if (!activeProject) return;
    const currentComponentId = currentComponent.id;
    if (connectType === ConnectType.INPUT) {
      const item = activeProject.graph.items.find(
        (item) => item.component_id === currentComponentId,
      );
      if (item) {
        item.next_component_ids.push(componentId);
      }
    } else {
      const item = activeProject.graph.items.find(
        (item) => item.component_id === componentId,
      );
      if (item) {
        item.next_component_ids.push(currentComponentId);
      }
    }
    await putProjectsByProjectId({
      path: {
        project_id: activeProject.id,
      },
      body: {
        name: activeProject.name,
        description: activeProject.description,
        graph: activeProject.graph,
      },
    });
    setActiveProject({ ...activeProject });
    setOpen(false);
  };

  const inputComponents = availableComponents.filter((c) =>
    componentTypeConfigs.component_type_input.includes(
      c.configuration.component_type,
    ),
  );
  const logicComponents = availableComponents.filter((c) =>
    componentTypeConfigs.component_type_processing.includes(
      c.configuration.component_type,
    ),
  );
  const outputComponents = availableComponents.filter((c) =>
    componentTypeConfigs.component_type_output.includes(
      c.configuration.component_type,
    ),
  );

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button size="sm" variant="ghost">
          <PlusCircledIcon /> Connect Component
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[80%] h-[80%] flex flex-col">
        <DialogHeader>
          <DialogTitle>Connect Component</DialogTitle>
          <DialogDescription>
            You can connect to current component or create new component
          </DialogDescription>
        </DialogHeader>
        <Tabs
          defaultValue="new-component"
          className="flex gap-4 flex-1 overflow-hidden"
        >
          <TabsList className="flex flex-col h-full justify-normal">
            <TabsTrigger value="new-component" className="w-full">
              New Components
            </TabsTrigger>
            <TabsTrigger value="current-component" className="w-full">
              Existing Components
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value="new-component"
            className="flex-1 h-full overflow-x-hidden overflow-y-auto"
          >
            <p className="text-sm text-muted-foreground">
              Create a new component and connect to it.
            </p>
            <div className="flex gap-2 flex-wrap">
              {[
                ...inputComponents,
                ...logicComponents,
                ...outputComponents,
              ].map((component) => (
                <div>
                  <InnerComponentPreview
                    component={component}
                    key={component.id}
                    onClick={onClickComponent}
                  />
                </div>
              ))}
            </div>
          </TabsContent>
          <TabsContent value="current-component">
            <p className="text-sm text-muted-foreground">
              Connect with current components of project
            </p>
            <div className="flex flex-col gap-2">
              {availableCurrentComponents.map((component) => (
                <InnerComponentPreview
                  component={component}
                  key={component.id}
                  onClick={onClickCurrentComponent}
                />
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNodeConnect;
