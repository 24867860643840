import axios from "axios";
import { postFiles, postFilesByFileIdCheck } from "client";

export const uploadFile = async (file: File) => {
  const res = await postFiles({
    body: {
      name: file.name,
      content_type: file.type,
      content_length: file.size,
    },
  });
  if (!res.data) return res.error;

  await axios.put(res.data.upload_url, file, {
    headers: {
      "Content-Type": res.data.file.content_type,
    },
  });
  const checkRes = await postFilesByFileIdCheck({
    path: {
      file_id: res.data.file.id,
    },
  });

  if (!checkRes.data) return checkRes.error;

  return checkRes.data;
};
