import * as React from "react";

import { Plus } from "lucide-react";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import {
  Component,
  getBasedComponents,
  postComponents,
  putProjectsByProjectId,
} from "client";
import useProjectStore from "storage/ProjectStore";
import InnerComponentPreview from "./InnerComponentPreview";

const CreateNode = () => {
  const [open, setOpen] = React.useState(false);
  const [baseComponents, setBaseComponents] = React.useState<Component[]>([]);
  const activeProject = useProjectStore((state) => state.activeProject);
  const componentTypeConfigs = useProjectStore(
    (state) => state.componentTypeConfigs,
  );
  const setActiveProject = useProjectStore((state) => state.setActiveProject);
  const setComponent = useProjectStore((state) => state.setComponent);

  React.useEffect(() => {
    getBasedComponents().then((data) => setBaseComponents(data.data || []));
  }, []);

  const addComponent = async (component: Component) => {
    if (!activeProject) return;
    const data = await postComponents({
      body: {
        component_name: component.component_name,
        configuration: component.configuration,
      },
    });

    if (data.data) {
      setComponent(data.data);
      activeProject.graph.items.push({
        component_id: data.data.id,
        next_component_ids: [],
      });
      await putProjectsByProjectId({
        path: {
          project_id: activeProject.id,
        },
        body: {
          name: activeProject.name,
          description: activeProject.description,
          graph: activeProject.graph,
        },
      });
      setActiveProject({ ...activeProject });
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          className="hover:scale-105 transition-all duration-200"
        >
          <Plus /> Add Input Component
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Input Component</DialogTitle>
          <DialogDescription>
            Choose the input component in the list below to start build awesome
            AI project
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          {baseComponents
            .filter((baseComponent) =>
              componentTypeConfigs.component_type_input.includes(
                baseComponent.configuration.component_type,
              ),
            )
            .map((baseComponent) => (
              <InnerComponentPreview
                key={baseComponent.id}
                component={baseComponent}
                onClick={() => addComponent(baseComponent)}
              />
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNode;
