import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { useProjectResults } from "storage/ProjectStore";
import ProjectRunItem from "./ProjectRunItem";

const ProjectRuns = () => {
  const results = useProjectResults();

  return (
    <Table>
      <TableCaption>A list of projects that have been run.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Run Id</TableHead>
          <TableHead className="w-[100px]">Run count</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Start at</TableHead>
          <TableHead>Duration</TableHead>
          <TableHead>Project Version</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {results.map((result) => (
          <ProjectRunItem item={result} key={result.result.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ProjectRuns;
